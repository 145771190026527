<template>
  <div class="order-wrap">
    <div class="order-main">
      <div class="notice-detail">
        <div class="cell-header">
          <h3>{{ data.title }}</h3>
          <p>{{ data.createTime }}</p>
        </div>
        <div class="loading" v-if="loading">
          <a-spin size="large"></a-spin>
        </div>
        <div v-else class="cell-body" v-html="data.content"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { fetchDetail } from '@/api/notice'

export default {
  name: 'notice',
  data () {
    return {
      data: {},
      loading: false
    }
  },
  created () {
    this.fetch()
  },
  methods: {
    async fetch () {
      try {
        this.loading = true
        this.data = await fetchDetail(this.$route.params.id)

        this.loading = false
      } catch (err) {
        this.loading = false
        this.$message.error(err.message)
      }
    }
  }
}
</script>

<style lang="less">
.notice-detail {
  .loading {
    margin: 100px 0;
    text-align: center;
  }

  .cell-header {
    text-align: center;

    h3 {
      line-height: 44px;
      border-bottom: 1px solid #e8e8e8;
    }
  }
}
</style>
